<template>
  <div class="pie-chart-container" :id="id">
    <!-- 饼状图 -->
  </div>
</template>
<script>
export default {
  name: "PieChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "pie-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas: {
      type: Array,
    },
  },
  data() {
    return {
      option: {
        title: {
          show: true,
          text: '报警类型统计',
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            fontSize: '18px',
            color: '#fff'
          },
          left: '35%',
          top: '45%'
        },
        tooltip: {
          show: false,
        },
        color: ['#063F94', '#F28E26', '#0BBAFB'],
        legend: {
          show: false,
          y: "center",
          align: "left",
          right: "5%",
          itemGap: 15,
          orient: "vertical",
          icon: "circle",
          itemHeight: 8,
          textStyle: {
            color: "#ffffff",
            fontSize: "12px",
          },
          itemStyle: {
            borderColor: "transparent",
          },
        },
        series: [
          {
            name: '报警类型统计',
            type: 'pie',
            radius: [80, 110],
            center: ['49%', '47%'],
            roseType: 'radius',
            itemStyle: {
              borderRadius: 0
            },
            label:{
              show: true,
              formatter: '{d}%',
              fontWeight: 400,
              fontSize: '18px',
              color: '#FFFFFF',
            },
            data: []
          }
        ],
      },
      chart: null,
      timer: null,
      pointer: 0,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.draw();
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  computed: {
    dataIndexArr() {
      return this.datas.map((item, index) => {
        return index;
      });
    },
  },
  methods: {
    draw() {
      this.option.series[0].data = this.datas;
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.pie-chart-container {
  width: 100%;
  // margin-left: -20%;
  height: 100%;
  position: relative;
}
</style>