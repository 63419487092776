<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="left">
        <div class="left-title">
          森林资源监测
        </div>
        <div class="section-1" @click="goPage('/investigationMonitor')">
          <div class="top">
            <div class="top-item">
              <i class="top-item-icon"></i>
              <div class="top-item-info-wrap">
                <div class="top-item-info-label">
                  林地面积(万亩)
                </div>
                <div class="top-item-info-val">
                  <countTo
                    :startVal="0"
                    :endVal="sumArea?sumArea.ldArea:0"
                    :duration="2000"
                    :decimals="2"
                    separator=""
                  ></countTo>
                </div>
              </div>
            </div>

            <div class="top-item">
              <i class="top-item-icon top-item-icon-1"></i>
              <div class="top-item-info-wrap">
                <div class="top-item-info-label">
                  森林面积(万亩)
                </div>
                <div class="top-item-info-val">
                <countTo
                  :startVal="0"
                  :endVal="sumArea?sumArea.slArea:0"
                  :duration="2000"
                  :decimals="2"
                  separator=""
                ></countTo>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <span class="label">
              森林覆盖率
            </span>
            <div class="percent-wrap">
              <i :class="['tree', item == 2 ? 'full':'', item == 1? 'half':'']"
                v-for="(item, index) in treeArr"
                :key="index"
              ></i>
            </div>
            <span class="value">
              {{treeNum}}%
            </span>
            <div class="line"></div>
          </div>
        </div>
<!--        @click="goPage('/investigationMonitor')"-->
        <div class="section-2" style="cursor: pointer;" >
          <div class="top-item">
            <i class="top-item-icon"></i>
            <div class="top-item-info-wrap" @click="getMapFun(10)">
              <div class="top-item-info-label">
                国有林场个数
              </div>
              <div class="top-item-info-val">
                <countTo
                  :startVal="0"
                  :endVal="SumNumArea.num"
                  :duration="2000"
                  :decimals="0"
                  separator=""
                ></countTo>
              </div>
            </div>
          </div>

          <div class="top-item">
            <i class="top-item-icon top-item-icon-1"></i>
            <div class="top-item-info-wrap">
              <div class="top-item-info-label">
                总面积（万亩）
              </div>
              <div class="top-item-info-val">

                <countTo
                  :startVal="0"
                  :endVal="SumNumArea.sumArea"
                  :duration="2000"
                  :decimals="0"
                  separator=","
                ></countTo>
              </div>
            </div>
          </div>
        </div>
<!--       -->
        <div class="section-3">
          <div class="item-wrap" @click="getMapFun(7)">
            <div class="item-label">
              自然保护地总数
            </div>
            <div class="item-val">

              <countTo
                :startVal="0"
                :endVal="natureTotalNum"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo>
            </div>
          </div>
        </div>
        <div class="section-4" @click="goPage('/ziranbaohudi')">
          <div class="top-item">
            <i class="top-item-icon"></i>
            <div class="top-item-info-wrap">
              <div class="top-item-info-label">
                地质公园
              </div>
              <div class="top-item-info-val">
                <countTo
                :startVal="0"
                :endVal="naturenum1"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo>
              </div>
            </div>
          </div>

          <div class="top-item">
            <i class="top-item-icon fjmsq"></i>
            <div class="top-item-info-wrap">
              <div class="top-item-info-label">
                风景名胜区
              </div>
              <div class="top-item-info-val">
                <countTo
                :startVal="0"
                :endVal="naturenum2"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo>
              </div>
            </div>
          </div>
          <div class="top-item">
            <i class="top-item-icon slgy"></i>
            <div class="top-item-info-wrap">
              <div class="top-item-info-label">
                森林公园
              </div>
              <div class="top-item-info-val">
                <countTo
                :startVal="0"
                :endVal="naturenum3"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo>
              </div>
            </div>
          </div>

          <div class="top-item ">
            <i class="top-item-icon sdgy"></i>
            <div class="top-item-info-wrap">
              <div class="top-item-info-label">
                湿地公园
              </div>
              <div class="top-item-info-val">
                <countTo
                :startVal="0"
                :endVal="naturenum4"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo>
              </div>
            </div>
          </div>
        </div>
        <div class="section-5">


          <div class="item-wrap lmlzzyk" @click="getMapFun(1)">
            <i class="item-icon lmlz"></i>
            <div class="item-label">国家级种质资源库</div>
            <div class="item-val"><countTo
                :startVal="0"
                :endVal="sumNumSeeds"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo></div>
          </div>

          <div class="item-wrap lmlzzyk" @click="getMapFun(2)">
            <i class="item-icon lmlz"></i>
            <div class="item-label">省级种质资源库</div>
            <div class="item-val"><countTo
                    :startVal="0"
                    :endVal="sumNumSeeds1"
                    :duration="2000"
                    :decimals="0"
                    separator=","
            ></countTo></div>
          </div>

          <div class="item-wrap " @click="getMapFun(0)">
            <i class="item-icon"></i>
            <div class="item-label">林木良种基地</div>
            <div class="item-val"><countTo
                    :startVal="0"
                    :endVal="sumNumGood"
                    :duration="2000"
                    :decimals="0"
                    separator=","
            ></countTo></div>
          </div>

          <div class="item-wrap ysdwyyjb" @click="getMapFun(3)">
            <i class="item-icon ysdwyyjb"></i>
            <div class="item-label" style="font-size:14px">野生动物疫源疾病监测站</div>
            <div class="item-val"><countTo
                :startVal="0"
                :endVal="sumNumMonitoring"
                :duration="2000"
                :decimals="0"
                separator=","
              ></countTo></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-top">
          <div class="right-top-map">
            <MapChart ref="mapChartRef" :datas="mapDatas" :dataList="mapDataList" @initMap="initMap" @checkMap="getMapData"></MapChart>
          </div>
          <div class="right-top-section" style="cursor: pointer;">
            <div class="right-top-section-title" @click="goPage('/forestFirePrevention')">
              森林防火
            </div>
            <div class="top-wrap" @click="getMapFun(8)">
              <div class="top-item">
                <div class="top-item-icon"></div>
                <div class="top-item-info">
                  <div class="top-item-info-label">
                    年度火情总数(起)
                  </div>
                  <div class="top-item-info-val">
                    <countTo
                      :startVal="0"
                      :endVal="dataInfo.yearFireNum"
                      :duration="2000"
                      :decimals="0"
                      separator=","
                    ></countTo>
                  </div>
                </div>
              </div>

              <div class="top-item">
                <div class="top-item-icon wxmhqs"></div>
                <div class="top-item-info">
                  <div class="top-item-info-label">
                    未熄灭火情数(起)
                  </div>
                  <div class="top-item-info-val">
                    <countTo
                      :startVal="0"
                      :endVal="dataInfo.firePendingNum"
                      :duration="2000"
                      :decimals="0"
                      separator=","
                    ></countTo>
                  </div>
                </div>
              </div>

            </div>
            <div class="pie-wrap">
              <pie-chart :datas="pieDatas"></pie-chart>
            </div>
            <div class="bottom-wrap">
              <div class="bottom-item" v-for="(item,index) in pieDatas" :key="index" v-if="item.name=='视频监控'">
                <div class="bottom-item-title">
                  视频监控报警
                </div>
                <div class="bottom-item-val">
                  <countTo
                    :startVal="0"
                    :endVal="item.value"
                    :duration="2000"
                    :decimals="0"
                    separator=","
                  ></countTo>
                </div>
              </div>

              <div class="bottom-item xhybj" v-for="(item,index) in pieDatas" :key="index" v-if="item.name=='巡护员'">
                <div class="bottom-item-title">
                  巡护员报警
                </div>
                <div class="bottom-item-val">
                  <countTo
                    :startVal="0"
                    :endVal="item.value"
                    :duration="2000"
                    :decimals="0"
                    separator=","
                  ></countTo>
                </div>
              </div>

              <div class="bottom-item wxdhbj" v-for="(item,index) in pieDatas" :key="index" v-if="item.name=='卫星监测'">
                <div class="bottom-item-title">
                  卫星点火报警
                </div>
                <div class="bottom-item-val">
                  <countTo
                    :startVal="0"
                    :endVal="item.value"
                    :duration="2000"
                    :decimals="0"
                    separator=","
                  ></countTo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-bottom">

          <!--          @click="goPage('/ancientBrightTrees')"-->
          <div class="right-bottom-item" style="cursor: pointer;" >
            <div class="card col-3-1" @click="getMapFun(6)">
              <div class="card-title" >
                古树总数
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{woodNum.sumNum}}-->
                   <countTo
                           :startVal="0"
                           :endVal="woodNum.sumNum"
                           :duration="2000"
                           :decimals="0"
                           separator=","
                   ></countTo>
                </span>
              </div>
            </div>

            <div class="card col-3-2" @click="goPage('/ancientBrightTrees')">
              <div class="card-title">
                名木（株）
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                 {{woodNum.preciousTrees}}-->
                  <countTo
                          :startVal="0"
                          :endVal="woodNum.preciousTrees"
                          :duration="2000"
                          :decimals="0"
                          separator=","
                  ></countTo>
                </span>
              </div>
            </div>

            <div class="card col-3-3" @click="goPage('/ancientBrightTrees')">
              <div class="card-title">
                古树群（个）
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{woodNum.oldTrees}}-->
                  <countTo
                          :startVal="0"
                          :endVal="woodNum.oldTrees"
                          :duration="2000"
                          :decimals="0"
                          separator=","
                  ></countTo>
                </span>
              </div>
            </div>
          </div>

<!--          @click="goPage('/linzhangzhi')"-->
          <div class="right-bottom-item" style="cursor: pointer;">
            <div class="card col-1-1" @click="getMapFun(5)">
              <div class="card-title">
                全省各级林长
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{getLeaderObj.leaderNum}}-->
                  <countTo
                          :startVal="0"
                          :endVal="getLeaderObj.leaderNum?getLeaderObj.leaderNum:0"
                          :duration="2000"
                          :decimals="0"
                          separator=","
                  ></countTo>
                  人
                </span>
              </div>
            </div>

            <div class="card col-1-2"  @click="goPage('/linzhangzhi')">
              <div class="card-title">
                全省林长制网格员
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{getLeaderObj.supervisor}}-->
                  <countTo
                          :startVal="0"
                          :endVal="getLeaderObj.supervisor?getLeaderObj.supervisor:0"
                          :duration="2000"
                          :decimals="0"
                          separator=","
                  ></countTo>
                  人
                </span>
              </div>
            </div>

            <div class="card col-1-3"  @click="goPage('/linzhangzhi')">
              <div class="card-title">
                公益林护林员
              </div>
              <div class="card-val-wrap">
                <div class="card-val card-val-1">
                  <i class="icon"></i>
                  <span class="card-val-text">
<!--                    {{duringPatrol.num_all}}-->
                    <countTo
                            :startVal="0"
                            :endVal="duringPatrol.num_all?duringPatrol.num_all:0"
                            :duration="2000"
                            :decimals="0"
                            separator=","
                    ></countTo>
                    人
                  </span>
                </div>
                <div class="card-title card-title-2">
                  巡逻中
                </div>
                <div class="card-val card-val-2">
                  <i class="icon"></i>
                  <span class="card-val-text">
<!--                    {{duringPatrol.num_on}}-->
                    <countTo
                            :startVal="0"
                            :endVal="duringPatrol.num_on?duringPatrol.num_on:0"
                            :duration="2000"
                            :decimals="0"
                            separator=","
                    ></countTo>
                    人
                  </span>
                </div>
              </div>
            </div>
          </div>
<!--          @click="goPage('/investigationMonitor')"-->
          <div class="right-bottom-item" style="cursor: pointer;" v-if="false">
<!--            <div class="card col-2-1">-->
<!--              <div class="card-title">-->
<!--                集体林权改革（亩）-->
<!--              </div>-->
<!--              <div class="card-val">-->
<!--                <i class="icon"></i>-->
<!--                <span class="card-val-text">-->
<!--                  3,801,111-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->

            <div class="card col-2-2" @click="getMapFun(9)">
              <div class="card-title">
                国储林（亩）
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{nationalStorageDeepForest}}-->
                   <countTo
                           :startVal="0"
                           :endVal="nationalStorageDeepForest"
                           :duration="2000"
                           :decimals="0"
                           separator=","
                   ></countTo>
                </span>
              </div>
            </div>

            <div class="card col-2-3" @click="getMapFun(4)">
              <div class="card-title">
                欧投行（亩）
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
<!--                  {{europeanInvestment}}-->
                  <countTo
                      :startVal="0"
                      :endVal="europeanInvestment"
                      :duration="2000"
                      :decimals="0"
                      separator=","
                  ></countTo>
                </span>
              </div>
            </div>
          </div>


          <div class="right-bottom-item" style="padding: 0;">
<!--            <p class="lykj">-->
<!--              林业科技-->
<!--            </p>-->
            <div class="lykj-item">
              <i class="icon"></i>
              <span class="val">
                无人机应用
              </span>
<!--              <span class="label" style="margin-left:10px">无人机应用</span>-->
            </div>
            <div class="lykj-item">
              <i class="icon icon-2"></i>
<!--              <span class="label" style="margin-left:10px">卫星应用</span>-->
              <span class="val">
                卫星应用
              </span>
            </div>
            <div class="bottom-wrap">
              <div class="bottom-item">
                <i class="icon"></i>
                <span class="val">
<!--                  <span class="label">科技示范园</span>-->
                  <span class="num">科技示范园</span>
                </span>
              </div>

              <div class="bottom-item">
                <i class="icon icon-2"></i>
                <span class="val">
<!--                  <span class="label">生态站</span>-->
                  <span class="num">生态站</span>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="right-bottom-item" style="padding: 0 26px;">
            <div class="top">
              <div class="top-item cur">
                有害生物
              </div>
              <div class="top-item">
                病虫害
              </div>
              <div class="top-item">
                虫害
              </div>
            </div>
            <div class="card col-5-1">
              <div class="card-title">
                汇总（亩）
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
                  1,031,268
                </span>
              </div>
            </div>

            <div class="card col-5-2">
              <div class="card-title">
                轻度
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
                  990,393
                </span>
              </div>
            </div>

            <div class="card col-5-3">
              <div class="card-title">
                中度
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
                  34,463
                </span>
              </div>
            </div>

            <div class="card col-5-4">
              <div class="card-title">
                重度
              </div>
              <div class="card-val">
                <i class="icon"></i>
                <span class="card-val-text">
                  6,412
                </span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import PieChart from "./components/pie-chart";
import MapChart from "./components/map-chart";
import Header from '../../components/header'
import {
  getSumNumGood,
  getSumNumSeeds,
  getSumNumMonitoring,
  getNumGroupGood,
  getNumGroupSeeds,
  getNumGroupMonitoring,
  nationalStorageDeepForest,
  europeanInvestment,
  getLeaderNum,
  getWoodNum,
  getPageInfo,
  getDuringPatrol,
  getPageInfoApi,
  getNumGroupNationalStorage,
  europeanInvestmentGroup,
  getFireNumGroupByRegion,
  getSumNumArea,
  getNumGroup,
  countryTreeFarm,
  nationalStorageDeepForestApi,
  europeanInvestmentApi,
  nationalStorageDeepForestsApi,
  woodsSeedsDb,
  woodsGoodBase,
  listNoPage,
  getDetailList,
  getSumArea
} from '@/api/integratedPlatform'
export default {
  data() {
    return {
      treeNum: 24.14,
      pieDatas: [
          { value: 852, name: "视频监控报警" },
          { value: 203, name: "巡护员报警" },
          { value: 130, name: "卫星点火报警" },
      ],
      mapDatas: {},
      mapDataList:[],
      sumNumGood:0,
      sumNumSeeds:0,
      sumNumSeeds1:0,
      sumNumMonitoring:0,
      nationalStorageDeepForest:0,
      europeanInvestment:0,
      mapRowData:{},
      getLeaderObj:{},
      woodNum:{},
      checkType:null,
      dataInfo:{},
      title:'国储林',
      duringPatrol:{},
      natureTotalNum:0,
      naturenum1:0,
      naturenum2:0,
      naturenum3:0,
      naturenum4:0,
      SumNumArea:{},
      detailList:[],
      sumArea:{}
    };
  },
  components: {
    PieChart,
    MapChart,
    Header,
  },
  methods: {
    getPageInfoFun(){
      getPageInfo().then(res=>{
        if(res.code==200){
          this.dataInfo=res.data
          let numClassifyArr=Object.keys(res.data.numClassify).map(key => ({ name:key, value: res.data.numClassify[key] }));
          this.pieDatas=numClassifyArr
        }
      })
    },
    goPage(path){
      this.$router.push({
        path
      })
    },
  //  良种基地
    getSumNumFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        county:''
      }
      getSumNumGood(data).then(res=>{
        if(res.code==200){
          this.sumNumGood=res.data
        }
      })
    },
  //  良种资质资源库
    getSumNumSeedsFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        county:'',
        level:'国家级'
      }
      getSumNumSeeds(data).then(res=>{
        if(res.code==200){
          this.sumNumSeeds=res.data
        }
      })
    },
  //  良种资质资源库
    getSumNumSeedsFun1(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        county:'',
        level:'省级'
      }
      getSumNumSeeds(data).then(res=>{
        if(res.code==200){
          this.sumNumSeeds1=res.data
        }
      })
    },
  //野生动物疫源疾病
    getSumNumMonitoringFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        county:'',
      }
      getSumNumMonitoring(data).then(res=>{
        if(res.code==200){
          this.sumNumMonitoring=res.data
        }
      })
    },
  //分组查询各地区数量
    getNumGroupGoodFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getNumGroupGood(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
    getNumGroupSeedsFun(e){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        level:e
      }
      getNumGroupSeeds(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
    getNumGroupMonitoringFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getNumGroupMonitoring(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
  //  国储林分组
    getNumGroupNationalStorageFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getNumGroupNationalStorage(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
  //  国储林
    nationalStorageDeepForestFun(){
      let data={
        city:'',
        county:'',
      }
      nationalStorageDeepForest(data).then(res=>{
        if(res.code==200){
          this.nationalStorageDeepForest=res.data
        }
      })
    },
    //欧投行
    europeanInvestmentFun(){
      let data={
        city:'',
        county:'',
      }
      europeanInvestment(data).then(res=>{
        if(res.code==200){
          this.europeanInvestment=res.data
        }
      })
    },
    // 欧投行分组
    europeanInvestmentGroupFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      europeanInvestmentGroup(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
  //  林长制
    getLeaderNumFun(type){
      let data={
        cityName:this.mapRowData.name?this.mapRowData.name:'',
      }
      getLeaderNum(data).then(res=>{
        if(res.code==200){
          this.getLeaderObj=res.data.summary
          if(type==1){
            this.mapDataList=res.data.list
            this.$refs.mapChartRef.setData(this.mapDataList,this.title)
          }
        }
      })
    },
  //  古木树
    getWoodNumFun(type){
      let data={
        pageNum:1,
        pageSize:10,
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getWoodNum(data).then(res=>{
        if(res.code==200){
          this.woodNum=res.rows[0]
          if(type==1){
            this.mapDataList=res.rows
            this.$refs.mapChartRef.setData(this.mapDataList,this.title)
          }
        }
      })
    },
  //获取巡护中的护林员
    getDuringPatrolFun(){
      let data={
        city:''
      }
      getDuringPatrol(data).then(res=>{
        if(res.code==200){
          this.duringPatrol=res.data
        }
      })
    },
  //  自然保护地
    getPageInfoApiFun(type){
      let data={
        regionId:this.mapRowData.name?this.mapRowData.name:'',
        type:''
      }
      getPageInfoApi(data).then(res=>{
        if(res.code==200){
          this.natureTotalNum=res.data.allNum
          this.natureList=res.data.getNumGroupByType
          if(type==1){
            this.mapDataList=res.data.getNumGroupByRegion
            this.$refs['mapChartRef'].setData(this.mapDataList,this.title)
          }
          res.data.getNumGroupByType.forEach(item=>{
            if(item.type2=='地质公园'){
              this.naturenum1=item.num
            }else if(item.type2=='风景名胜区'){
              this.naturenum2=item.num
            }else if(item.type2=='森林公园'){
              this.naturenum3=item.num
            }else if(item.type2=='湿地公园'){
              this.naturenum4=item.num
            }
          })
        }
      })
    },
  //  森林防火
    getFireNumGroupByRegionFun(){
      let data={
        regionName:this.mapRowData.name?this.mapRowData.name:'',
      }
      getFireNumGroupByRegion(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
  //  国有林场
    getSumNumAreaFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        county:'',
      }
      getSumNumArea(data).then(res=>{
        if(res.code==200){
          this.SumNumArea=res.data
        }
      })
    },
    getNumGroupFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getNumGroup(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
  //  国有林场坐标
    countryTreeFarmFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      countryTreeFarm(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)

        }
      })
    },
  //  欧投行坐标
    europeanInvestmentApiFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      europeanInvestmentApi(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
  //  国储林坐标
    nationalStorageDeepForestApiFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      nationalStorageDeepForestApi(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)

        }
      })
    },
    //  野生动物坐标
    nationalStorageDeepForestsApiFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      nationalStorageDeepForestsApi(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
    //  两种资源库坐标
    woodsSeedsDbFun(type){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        level:type
      }
      woodsSeedsDb(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
    //  林木良种坐标
    woodsGoodBaseFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      woodsGoodBase(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.latitude,item.longitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
    //古木名树坐标
    listNoPageFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      listNoPage(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.longitude,item.latitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
    //自然保护地坐标
    getDetailListFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
        type2:''
      }
      getDetailList(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.longitude,item.latitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
  //  森林面积
    getSumAreaFun(){
      getSumArea().then(res=>{
        if(res.code==200){
          this.sumArea=res.data
        }
      })
    },
  //点击地图下级
    getMapData(e){
      console.log(e,'type')
      if(e){
        this.mapRowData=e
        if(this.checkType!=null){
          if(this.checkType==0){//林木良种基地
            this.getSumNumFun()
            this.getNumGroupGoodFun()
            this.woodsGoodBaseFun()
            return
          }else if(this.checkType==1){//资源库
            this.getSumNumSeedsFun()
            this.getNumGroupSeedsFun('国家级')
            this.woodsSeedsDbFun('国家级')
            return
          }else if(this.checkType==2){//资源库
            this.getSumNumSeedsFun1()
            this.getNumGroupSeedsFun('省级')
            this.woodsSeedsDbFun('省级')
            return
          }else if(this.checkType==3){//野生动物疫源
            this.getNumGroupMonitoringFun()
            this.getSumNumMonitoringFun()
            this.nationalStorageDeepForestsApiFun()
            return
          }else if(this.checkType==4){//欧投行
            this.europeanInvestmentGroupFun()
            this.europeanInvestmentApiFun()
            return
          }else if(this.checkType==5){//林长制
            this.getLeaderNumFun(1)
            return
          }else if(this.checkType==6){//古树名木
            this.getWoodNumFun(1)
            this.listNoPageFun()
            return
          }else if(this.checkType==7){//自然保护地
            this.getPageInfoApiFun(1)
            this.getDetailListFun()
            return
          }else if(this.checkType==8){//森林防火
            this.getFireNumGroupByRegionFun()
            return
          }else if(this.checkType==9){//国储林
            this.getNumGroupNationalStorageFun()
            this.nationalStorageDeepForestApiFun()
            return
          }else if(this.checkType==10){//国有林场
            this.getNumGroupFun()
            this.countryTreeFarmFun()
            return
          }
        }else{
          this.getNumGroupNationalStorageFun()
          this.nationalStorageDeepForestApiFun()
          return
        }
      }else{
        this.getNumGroupNationalStorageFun()
        this.nationalStorageDeepForestApiFun()
        return
      }

    },
  //  清空
    initMap(){
      this.mapRowData=''
      this.title='国储林'
      if(this.checkType==0){//林木良种基地
        this.getSumNumFun()
        this.getNumGroupGoodFun()
        this.woodsGoodBaseFun()
        return
      }else if(this.checkType==1){//资源库
        this.getNumGroupSeedsFun('国家级')
        this.woodsSeedsDbFun('国家级')
        this.getSumNumSeedsFun()
        return
      }else if(this.checkType==2){//资源库
        this.getNumGroupSeedsFun('省级')
        this.woodsSeedsDbFun('省级')
        this.getSumNumSeedsFun1()
        return
      }else if(this.checkType==3){//野生动物疫源
        this.getSumNumMonitoringFun()
        this.getNumGroupMonitoringFun()
        this.nationalStorageDeepForestsApiFun()
        return
      }else if(this.checkType==4){//欧投行
        this.europeanInvestmentGroupFun()
        this.europeanInvestmentApiFun()
        return
      }else if(this.checkType==5){//林长制
        this.getLeaderNumFun(1)
        return
      }else if(this.checkType==6){//古树名木
        this.getWoodNumFun(1)
        return
      }else if(this.checkType==7){//自然保护地
        this.getPageInfoApiFun(1)
        return
      }else if(this.checkType==8){//森林防火
        this.getFireNumGroupByRegionFun()
        return
      }else if(this.checkType==9){//国储林
        this.getNumGroupNationalStorageFun()
        return
      }else if(this.checkType==10){//国有林场
        this.getNumGroupFun()
        return
      }else if(this.checkType==null){//国储林
        this.getNumGroupNationalStorageFun()
        return
      }
      this.detailList=[]
    },
  //  点击分类分布地图
    getMapFun(type){
      this.checkType=type
      if(type==0){
        this.title='林木良种基地'
        this.getNumGroupGoodFun()
        // this.woodsGoodBaseFun()
      }else if(type==1){
        this.title='种质资源库国有'
        this.getNumGroupSeedsFun('国家级')
        // this.woodsSeedsDbFun('国家级')
      }else if(type==2){
        this.title='种质资源库省级'
        this.getNumGroupSeedsFun('省级')
        // this.woodsSeedsDbFun('省级')
      }else if(type==3){
        this.title='野生动物疫源疾病'
        this.getNumGroupMonitoringFun()
        // this.nationalStorageDeepForestsApiFun()
      }else if(type==4){//欧投行
        this.title='欧投行'
        this.europeanInvestmentApiFun()
        // this.europeanInvestmentGroupFun()
      }else if(type==5){//林长制
        this.title='林长人数'
        this.getLeaderNumFun(1)
      }else if(type==6){//古树名木
        this.title='古树名木总数'
        this.getWoodNumFun(1)
        // this.listNoPageFun()
      }else if(type==7){//自然保护地
        this.title='自然保护地总数'
        this.getPageInfoApiFun(1)
        // this.getDetailListFun()
      }else if(type==8){//森林防火
        this.title='年度火情总数'
        this.getFireNumGroupByRegionFun()
      }else if(type==9){//国储林
        this.title='国储林'
        this.getNumGroupNationalStorageFun()
        this.nationalStorageDeepForestApiFun()
      }else if(type==10){//国有林场
        this.title='国有林场个数'
        this.getNumGroupFun()
        // this.countryTreeFarmFun()
      }
    },
  },
  mounted(){
    this.getSumNumFun()
    this.getSumAreaFun()
    this.getWoodNumFun(0)
    this.getPageInfoFun()
    this.getLeaderNumFun(0)
    this.getSumNumAreaFun()
    this.getPageInfoApiFun(0)
    this.getSumNumSeedsFun()
    this.getSumNumSeedsFun1()
    this.getDuringPatrolFun()
    this.europeanInvestmentFun()
    this.getSumNumMonitoringFun()
    this.nationalStorageDeepForestFun()
    this.getNumGroupNationalStorageFun()
    // this.nationalStorageDeepForestApiFun()
  },
  computed: {
    treeArr(){
      const _arr = new Array(10).fill(0);
      const _treeNumArr = (this.treeNum + '' || '').split('.')
      for(let i = 0; i<_treeNumArr[0].length;i++){
        _arr[i] = 2
      }
      if(_treeNumArr[1]){
        _arr[_treeNumArr[0].length] = 1
      }
      return _arr
    },
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;


  .body{
    height: calc(100% - 119px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;

    .left{
      width: 439px;
      height: 100%;
      background: url('./images/slzyjkbg@2x.png') center center no-repeat;
      background-size: 100% 100%;
      padding: 49px 6px 0 18px;
      position: relative;
      .left-title{
        position: absolute;
        width: 100%;
        text-align: left;
        left: 65px;
        top: 3px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 24px;
        color: #D9EAF5;
        text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
        font-style: italic;
        background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .section-1{
        cursor: pointer;
        width: 100%;
        padding-bottom: 19px;
        height: 183px;
        background: url('./images/z1@2x.png') center center no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        .top{
          flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .top-item{
            display: flex;
            .top-item-icon{
              display: block;
              width: 50px;
              height: 50px;
              background: url('./images/ldmj@2x.png') center center no-repeat;
              background-size: 100%;
              &.top-item-icon-1{
                background: url('./images/slmj.png') center center no-repeat;
                background-size: 100%;
              }
            }
            .top-item-info-wrap{
              flex: 1;
              margin-left: 10px;
              text-align: left;
              .top-item-info-label{
                white-space: nowrap;
                width: 122px;
                height: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 16px;
              }
              .top-item-info-val{
                margin-top: 8px;
                width: 92px;
                height: 26px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 26px;
                color: #69FFFA;
                line-height: 26px;
              }
            }
          }
        }
        .bottom{
          height: 35px;
          width: 100%;
          line-height: 35px;
          background: rgba(73,255,146,0.19);
          display: flex;
          position: relative;
          padding: 2px 10px 0 14px;
          .label{
            width: 71px;
            margin-right: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #00B0ED;
            line-height: 36px;
            background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .percent-wrap{
            display: flex;
            .tree{
              width: 17px;
              height: 25px;
              margin-left: 7px;
              background: url('./images/tree.png') center center no-repeat;
              background-size: 100% 100%;
              &:first-of-type{
                margin-left: 7px;
              }
              &.full{
                background: url('./images/tree2.png') center center no-repeat;
                background-size: 100% 100%;
              }
              &.half{
                background: url('./images/tree1.png') center center no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          .value{
            width: 51px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #FFFFFF;
            line-height: 36px;
            margin-left: 14px;
          }
          .line{
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -3px;
            left: 0;
            background-color: #22C764;
            &::after{
              content: "";
              display: block;
              width: 5px;
              height: 5px;
              background: #22C764;
              position: absolute;
              right: 0;
              top: -2px;
            }
            &::before{
              content: "";
              display: block;
              width: 5px;
              height: 5px;
              background: #22C764;
              position: absolute;
              left: 0;
              top: -2px;
            }
          }
        }
      }
      .section-2{
        width: 100%;
        margin-top: 8px;
        height: 124px;
        background: url('./images/z2bg.png') center center no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .top-item{
          display: flex;
          .top-item-icon{
            display: block;
            width: 48px;
            height: 48px;
            background: url('./images/gylcgs.png') center center no-repeat;
            background-size: 100%;
            &.top-item-icon-1{
              background: url('./images/zmj.png') center center no-repeat;
              background-size: 100%;
            }
          }
          .top-item-info-wrap{
            flex: 1;
            margin-left: 10px;
            text-align: left;
            .top-item-info-label{
              width: 122px;
              height: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #FFFFFF;
              line-height: 16px;
            }
            .top-item-info-val{
              margin-top: 8px;
              width: 92px;
              height: 26px;
              font-family: PangMenZhengDao;
              font-weight: 400;
              font-size: 26px;
              color: #69FFFA;
              line-height: 26px;
            }
          }
        }
      }
      .section-3{
        margin-top: 4px;
        height: 82px;
        background: url('./images/z3bg.png') center center no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        .item-wrap{
          width: 113px;
          .item-label{
            white-space: nowrap;
            height: 17px;
            line-height: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
          }
          .item-val{
            margin: 15px auto 0 auto;
            width: 69px;
            height: 24px;
            line-height: 24px;
            font-family: PangMenZhengDao;
            font-weight: 400;
            font-size: 35px;
            color: #FFFFFF;
            background: linear-gradient(0deg, #19FFF4 0%, #AEFFAE 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .section-4{
        height: 178px;
        margin-top: 8px;
        background: url('./images/z4bg.png') center center no-repeat;
        background-size: 100% 100%;
        padding: 20px 51;
        display: flex;
        flex-wrap: wrap;
        padding-left: 45px;
        .top-item{
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 163px;
          display: flex;
          .top-item-icon{
            display: block;
            width: 48px;
            height: 48px;
            background: url('./images/dzgy.png') center center no-repeat;
            background-size: 100%;
            &.fjmsq{
              background: url('./images/fjmsq.png') center center no-repeat;
              background-size: 100%;
            }
            &.slgy{
              background: url('./images/slgy.png') center center no-repeat;
              background-size: 100%;
            }
            &.sdgy{
              background: url('./images/sdgy.png') center center no-repeat;
              background-size: 100%;
            }

          }
          .top-item-info-wrap{
            flex: 1;
            margin-left: 32px;
            text-align: left;
            white-space: nowrap;
            .top-item-info-label{
              height: 17px;
              line-height: 17px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #A2B7D9;
            }
            .top-item-info-val{
              margin-top: 12px;
              line-height: 18px;
              font-family: PangMenZhengDao;
              font-weight: 400;
              font-size: 25px;
              color: #84E077;
              background: linear-gradient(0deg, #19FFF4 0%, #AEFFAE 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .section-5{
        margin-top: 6px;
        height: 324px;
        padding: 25px 35px;
        background: url('./images/z5bg.png') center center no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-wrap: wrap;
        .item-wrap{
          width: 160px;
          height: 126px;
          margin-left: 24px;
          padding-top: 28px;
          background: url('./images/lmlzjdbg.png') center center no-repeat;
          background-size: 100% 100%;
          &.lmlzzyk{
            background: url('./images/lmlzzzykBG@2x.png') center center no-repeat;
            background-size: 100% 100%;
          }
          &.ysdwjz{
            background: url('./images/ysdwjzBG@2x.png') center center no-repeat;
            background-size: 100% 100%;
          }
          &.ysdwyyjb{
            background: url('./images/ysdwyyjbBG@2x.png') center center no-repeat;
            background-size: 100% 100%;
          }
          &:nth-of-type(2n+1){
            margin-left: 0;
          }
          .item-icon{
            width: 45px;
            height: 27px;
            display: block;
            margin: 0 auto;
            background: url('./images/lmlzjd-icon@2x.png') center center no-repeat;
            background-size: 100% 100%;
            &.lmlz{
              margin-top: -6px;
              width: 36px;
              height: 32px;
              background: url('./images/lmlzzykicon@2x.png') center center no-repeat;
              background-size: 100% 100%;
            }
            &.ysdwjz{
              margin-top: -8px;
              width: 42px;
              height: 37px;
              background: url('./images/ysdwjzicon@2x.png') center center no-repeat;
              background-size: 100% 100%;
            }
            &.ysdwyyjb{
              margin-top: -8px;
              width: 34px;
              height: 35px;
              background: url('./images/ysdwyyjb-icon@2x.png') center center no-repeat;
              background-size: 100% 100%;
            }
          }
          .item-label{
            height: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 21px;
            margin: 8px auto 0 auto;
          }
          .item-val{
            margin: 14px auto 0 auto;
            font-family: PangMenZhengDao;
            font-weight: 400;
            font-size: 26px;
            color: #69FFFA;
            line-height: 21px;
            background: linear-gradient(0deg, #19FFF4 0%, #AEFFAE 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

      }
    }
    .right{
      flex: 1;
      height: 100%;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      .right-top{
        height: 616px;
        display: flex;
        justify-content: space-evenly;
        .right-top-map{
          width: 923px;
          height: 100%;
        }
        .right-top-section{
          width: 436px;
          height: 100%;
          background: url('./images/slfhbg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 70px;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          .right-top-section-title{
            left: 55px;
            top: 3px;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .top-wrap{
            display: flex;
            justify-content: space-around;
            .top-item{
              width: 180px;
              display: flex;
              .top-item-icon{
                width: 48px;
                height: 48px;
                background: url('./images/ndhqzsicon@2x.png') center center no-repeat;
                background-size: 100% 100%;
                margin-right: 12px;
                &.wxmhqs{
                  background: url('./images/wxmhqzsicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
              .top-item-info{
                flex: 1;
                .top-item-info-label{
                  height: 17px;
                  line-height: 17px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #EB7286;
                  background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
                .top-item-info-val{
                  margin-top: 14px;
                  height: 17px;
                  line-height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 26px;
                  color: #FF4141;
                }
              }

            }
          }
          .pie-wrap{
            height: 390px;
          }
          .bottom-wrap{
            display: flex;
            justify-content: space-around;
            .bottom-item{
              width: 123px;
              height: 73px;
              background: url('./images/spjkbjBG@2x.png') center center no-repeat;
              background-size: 100% 100%;

              &.xhybj{
                background: url('./images/xhybjBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
              }

              &.wxdhbj{
                background: url('./images/wxdhbjBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
              }
              .bottom-item-title{
                margin: 14px 0;
                height: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 15px;
              }
              .bottom-item-val{
                height: 20px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 20px;
              }
            }
          }
        }
      }
      .right-bottom{
        flex: 1;
        // margin-top: 27px;
        background: url('./images/xbg@2x.png') center center no-repeat;
        background-size: 100% 100%;
        padding: 55px 0 8px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-bottom-item{
          /*width: 322px;*/
          width: 442px;
          position: relative;
          height: 100%;
          padding: 0 34px 0 40px;
          &:first-of-type::before{
            content: '';
            display: block;
            width: 3px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: url('./images/fgx@2x.png') center center no-repeat;
            background-size: 100% 100%;
          }
          &::after{
            content: '';
            display: block;
            width: 3px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: url('./images/fgx@2x.png') center center no-repeat;
            background-size: 100% 100%;
          }
          .card{
            .card-title{
              height: 15px;
              line-height: 15px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
            }
            .card-val{
              height: 43px;
              background: url('./images/lzbg.png') center center no-repeat;
              background-size: 100% 100%;
              display: flex;
              align-items: center;
              padding-left: 16px;
              .icon{
                display: block;
                width: 27px;
                height: 27px;
                background: url('./images/lzicon@2x.png') center center no-repeat;
                background-size: 100% 100%;
              }
              .card-val-text{
                flex: 1;
                /*padding-left: 28px;*/
                text-align: center;
                height: 19px;
                line-height: 19px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 25px;
                color: #FFFFFF;
              }
            }

            &.col-1-2{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                background: url('./images/wgyBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/wgyicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-1-3{
              .card-title{
                margin-top: 6px;
              }
              .card-title-2{
                margin-top: 0;
                color: #39F65D;
              }
              .card-val-wrap{
                background: url('./images/hlyBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .card-val{
                  background: none;
                }
                .card-val-1{
                  .icon{
                    background: url('./images/hlyicon@2x.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  .card-val-text{
                    color: #3DEAFF;
                  }
                }
                .card-val-2{
                  .icon{
                    background: url('./images/xlzicon@2x.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  .card-val-text{
                    color: #39F65D;
                  }
                }
              }

            }

            &.col-2-1{
              .card-val{
                height: 55px;
                background: url('./images/jtlqggBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/jtlqggicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-2-2{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 55px;
                background: url('./images/gclBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/gclicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-2-3{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 55px;
                background: url('./images/gclBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/othicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-3-1{
              .card-val{
                height: 55px;
                background: url('./images/jtlqggBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/gszsicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-3-2{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 55px;
                background: url('./images/gclBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/msicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-3-3{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 55px;
                background: url('./images/gclBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/gsqicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-5-1{
              .card-val{
                height: 42px;
                .icon{
                  background: url('./images/hzicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-5-2{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 42px;
                background: url('./images/gclBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/qdicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-5-3{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 42px;
                background: url('./images/zdBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/zdicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &.col-5-4{
              .card-title{
                margin-top: 6px;
              }
              .card-val{
                height: 42px;
                background: url('./images/zhongduBG@2x.png') center center no-repeat;
                background-size: 100% 100%;
                .icon{
                  background: url('./images/zhongduicon@2x.png') center center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }

          .lykj{
            width: 100%;
            height: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 15px;
            text-align: center;
          }
          .lykj-item{
            margin: 8px auto 0 auto;
            width: 200px;
            height: 47px;
            background: url('./images/lykjBG@2x.png') center center no-repeat;
            background-size: 100% 100%;
            display: flex;
            padding: 0 20px;
            align-items: center;
            .icon{
              display: block;
              width: 54px;
              height: 31px;
              background: url('./images/wrj@2x.png') center center no-repeat;
              background-size: 100% 100%;
              &.icon-2{
                width: 38px;
                height: 37px;
                background: url('./images/wx@2x.png') center center no-repeat;
                background-size: 100% 100%;
              }
            }
            .val{
              flex: 1;
              text-align: center;
              height: 13px;
              line-height: 13px;
              font-family: PangMenZhengDao;
              font-weight: 400;
              font-size: 20px;
              color: #2BE29B;
            }
          }
          .bottom-wrap{
            margin-top: 12px;
            display: flex;
            justify-content: space-evenly;
            .bottom-item{
              width: 118px;
              .icon{
                display: block;
                width: 96px;
                height: 91px;
                margin: 0 auto;
                background: url('./images/kjsfy@2x.png') center bottom no-repeat;
                background-size: 100% auto;
                &.icon-2{
                  background: url('./images/stz@2x.png') center bottom no-repeat;
                  background-size: 100% auto;
                }
              }
              .val{
                margin-top: 8px;
                .label{
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                  line-height: 21px;
                }
                .num{
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 20px;
                  color: #2BE29B;
                  line-height: 21px;
                }
              }
            }
          }

          .top{
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 8px;
            .top-item{
              flex: 1;
              padding: 6px;
              position: relative;
              line-height: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
              cursor: pointer;
              &.cur::after{
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: linear-gradient(0deg, #FFAB96 0%, #FF0F47 100%);
              }
            }
          }
        }
      }
    }
  }
}
</style>
